// 大会决议
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'ZLMC',
                label: '资料名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'GBSJ',
                label: '公布时间',
                minWidth: 100,
                sortable: 'custom'
            }, {
                prop: 'LX',
                label: '类型',
                // transList: {
                //     1: '列入时决议',
                //     2: '补充决议',
                //     3: '补充决议'
                // },
                // colorList: {
                //     1: '#606266',
                //     2: '#606266',
                //     3: '#606266',
                // }
            }, {
                prop: 'DHJYWDH',
                label: '大会决议文档号',
                minWidth: 120
            }, {
                prop: 'ZWNR',
                label: '中文内容',
                minWidth: 80,
                showoverflowtooltip: true
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
    snSearch: {
        selectData: [{
            label: '类型',
            clearable: true,
            placeholder: "请选择大会决议类型",
            list: [{
                ID: '1',
                NAME: '列入时决议'
            }, {
                ID: '2',
                NAME: '补充决议'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'LX',
            operateType: 'search',
            isShow: true
        }],
    },
};
export default selfData;